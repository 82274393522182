import axios from '@/plugins/axios'
import customErrors from '@/store/error'
import convertObjToArray from '@/helpers/convertObjToArray'

const state = () => ({
  userItems: [],
})

const mutations = {
  SET_USER_ITEMS(state, items) {
    state.userItems = convertObjToArray(items)
  },
}

const actions = {
  async GET_USER_ITEMS({commit, dispatch}) {
    try {
      const res = await axios.get('dictionary/filter/clients/read-all')
      if (res.status === 200) {
        commit('SET_USER_ITEMS', res.data)
      }
    } catch (err) {
      customErrors(err, dispatch)
      commit('SET_USER_ITEMS', [])
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
