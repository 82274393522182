export const STATUS_ITEMS = [
  {
    text: 'statusItems.active',
    value: 'ACTIVE',
  },
  {
    text: 'statusItems.inactive',
    value: 'INACTIVE',
  },
]
