const roles = ['ROLE_ADMIN']

export default [
  {
    path: '/admin/dashboard',
    name: 'AdminDashboard',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "admin-dashboard" */ '@/views/dashboard/Dashboard'),
  },
  {
    path: '/admin/clients',
    name: 'AdminClients',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "admin-clients" */ '@/views/clients/Clients'),
  },
  {
    path: '/admin/clients/detail/:id',
    name: 'AdminClientDetail',
    props: true,
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "admin-client-detail" */ '@/views/clients/ClientDetail'),
  },
  {
    path: '/admin/clients/new',
    name: 'AdminNewClient',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "admin-client-new" */ '@/views/clients/ClientDetail'),
  },
  {
    path: '/admin/statistics',
    name: 'AdminStatistics',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "admin-statistics" */ '@/views/statistics/Statistics'),
  },
  {
    path: '/admin/campaigns',
    name: 'AdminCampaigns',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "admin-campaigns" */ '@/views/campaigns/Campaigns'),
  },
  {
    path: '/admin/creatives',
    name: 'AdminCreatives',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "admin-creatives" */ '@/views/creatives/Creatives'),
  },
  {
    path: '/admin/creative/detail/:id',
    name: 'AdminCreativeDetail',
    props: true,
    meta: {layout: 'main', auth: true, role: roles},
    component: () =>
      import(/* webpackChunkName: "admin-creative-detail" */ '@/views/creatives/CreativeDetail'),
  },
  {
    path: '/admin/creative/new',
    name: 'AdminNewCreative',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "admin-creative-new" */ '@/views/creatives/CreativeDetail'),
  },
  {
    path: '/admin/targets',
    name: 'AdminTargets',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "admin-targets" */ '@/views/targets/Targets'),
  },
  {
    path: '/admin/targets/detail/:id',
    name: 'AdminTargetDetail',
    props: true,
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "admin-target-detail" */ '@/views/targets/TargetDetail'),
  },
  {
    path: '/admin/targets/new',
    name: 'AdminNewTarget',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "admin-target-new" */ '@/views/targets/TargetDetail'),
  },
  {
    path: '/admin/segments',
    name: 'AdminSegments',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "admin-segments" */ '@/views/segments/Segments'),
  },
  {
    path: '/admin/rules',
    name: 'AdminRules',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "admin-rules" */ '@/views/rules/Rules'),
  },
  {
    path: '/admin/integrations',
    name: 'AdminIntegrations',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "admin-integrations" */ '@/views/integrations/Integrations'),
  },
  {
    path: '/admin/doc',
    name: 'AdminDoc',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "admin-doc" */ '@/views/doc/Doc'),
  },
  {
    path: '/admin/tags',
    name: 'AdminTags',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "admin-tags" */ '@/views/tags/Tags'),
  },
]
