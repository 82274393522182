<template>
  <v-dialog v-model="show" v-bind="$attrs" :max-width="maxWidth" transition="scroll-y-transition">
    <v-card :min-height="minHeight" class="pa-5 px-sm-10">
      <div v-if="isShowClose" class="text-end">
        <v-btn text color="red" @click="close">
          <v-icon size="16" left>mdi-close</v-icon>
          {{ $t('button.close') }}
        </v-btn>
      </div>
      <slot />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CustomDialog',
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: [String, Number],
      default: 620,
    },
    minHeight: {
      type: [String, Number],
      default: 300,
    },
    isShowClose: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({}),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    close() {
      this.show = false
    },
  },
}
</script>
