const adminRoutes = {
  dashboard: {
    order: 1,
    route: {name: 'AdminDashboard'},
  },
  clients: {
    order: 2,
    route: {name: 'AdminClients'},
  },
  statistics: {
    order: 3,
    route: {name: 'AdminStatistics'},
  },
  campaigns: {
    order: 4,
    route: {name: 'AdminCampaigns'},
  },
  creatives: {
    order: 5,
    route: {name: 'AdminCreatives'},
  },
  targets: {
    order: 6,
    route: {name: 'AdminTargets'},
  },
  segments: {
    order: 7,
    route: {name: 'AdminSegments'},
  },
  rules: {
    order: 8,
    route: {name: 'AdminRules'},
  },
  integrations: {
    order: 9,
    route: {name: 'AdminIntegrations'},
  },
  doc: {
    order: 10,
    route: {name: 'AdminDoc'},
  },
  tags: {
    order: 11,
    route: {name: 'AdminTags'},
  },
}

export default adminRoutes
