<template>
  <v-switch
    v-model="switchValue"
    v-bind="$attrs"
    :inset="inset"
    color="blue"
    :hide-details="hideDetails"
    class="custom-switch"
    :class="hideDetails ? 'mt-0 pt-0' : ''"
    v-on="$listeners"
  >
    <slot v-for="slot in Object.keys($slots)" :slot="slot" :name="slot" />
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-switch>
</template>

<script>
export default {
  name: 'CustomSwitch',
  inheritAttrs: false,
  props: {
    value: {
      type: [Boolean, String, Number],
      default: false,
    },
    inset: {
      type: Boolean,
      default: true,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    switchValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
