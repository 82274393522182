const roles = ['ROLE_MANAGER']

export default [
  {
    path: '/manager/clients',
    name: 'ManagerClients',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "manager-clients" */ '@/views/clients/Clients'),
  },
  {
    path: '/manager/clients/detail/:id',
    name: 'ManagerClientDetail',
    props: true,
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "manager-client-detail" */ '@/views/clients/ClientDetail'),
  },
  {
    path: '/manager/clients/new',
    name: 'ManagerNewClient',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "manager-client-new" */ '@/views/clients/ClientDetail'),
  },
  {
    path: '/manager/dashboard',
    name: 'ManagerDashboard',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "manager-dashboard" */ '@/views/dashboard/Dashboard'),
  },
  {
    path: '/manager/statistics',
    name: 'ManagerStatistics',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "manager-statistics" */ '@/views/statistics/Statistics'),
  },
  {
    path: '/manager/campaigns',
    name: 'ManagerCampaigns',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "manager-campaigns" */ '@/views/campaigns/Campaigns'),
  },
  {
    path: '/manager/creatives',
    name: 'ManagerCreatives',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "manager-creatives" */ '@/views/creatives/Creatives'),
  },
  {
    path: '/manager/creative/detail/:id',
    name: 'ManagerCreativeDetail',
    props: true,
    meta: {layout: 'main', auth: true, role: roles},
    component: () =>
      import(/* webpackChunkName: "manager-creative-detail" */ '@/views/creatives/CreativeDetail'),
  },
  {
    path: '/manager/creative/new',
    name: 'ManagerNewCreative',
    meta: {layout: 'main', auth: true, role: roles},
    component: () =>
      import(/* webpackChunkName: "manager-creative-new" */ '@/views/creatives/CreativeDetail'),
  },
  {
    path: '/manager/targets',
    name: 'ManagerTargets',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "manager-targets" */ '@/views/targets/Targets'),
  },
  {
    path: '/manager/targets/detail/:id',
    name: 'ManagerTargetDetail',
    props: true,
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "manager-target-detail" */ '@/views/targets/TargetDetail'),
  },
  {
    path: '/manager/targets/new',
    name: 'ManagerNewTarget',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "manager-target-new" */ '@/views/targets/TargetDetail'),
  },
  {
    path: '/manager/segments',
    name: 'ManagerSegments',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "manager-segments" */ '@/views/segments/Segments'),
  },
  {
    path: '/manager/rules',
    name: 'ManagerRules',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "manager-rules" */ '@/views/rules/Rules'),
  },
  {
    path: '/manager/doc',
    name: 'ManagerDoc',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "manager-doc" */ '@/views/doc/Doc'),
  },
]
