import axios from 'axios'
import {getItem} from '@/helpers/persistantStorage'

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
})

api.interceptors.request.use(
  (config) => {
    const token = getItem('nt_token')
    if (token) {
      config.headers['Authorization'] = token
    }
    return config
  },

  (error) => {
    return Promise.reject(error)
  }
)

export default api
