<template>
  <v-row class="justify-space-between align-center mb-15">
    <v-col cols="12" sm="5" md="3">
      <CustomSelect
        v-model.trim="params.value"
        v-if="params.type === 'select'"
        append-icon="mdi-magnify"
        :items="mapSelectItems(params.name)"
        :placeholder="$t('label.' + params.placeholder)"
        hide-details
        :outlined="false"
        @change="$emit('add')"
      />
      <CustomInput
        v-model.trim="params.value"
        v-else
        append-icon="mdi-magnify"
        :placeholder="$t('label.' + params.placeholder)"
        hide-details
        :outlined="false"
        @keydown.enter="$emit('add')"
      />
    </v-col>
    <v-col v-if="selectedFilters.length" cols="12" sm="7" md="7">
      <CustomChip v-for="param in selectedFilters" :key="param.name" @click:close="$emit('delete', param)">
        {{ substringLength(param.value) }}
      </CustomChip>
    </v-col>
    <v-col cols="12" sm="5" md="2" class="d-md-flex justify-end">
      <ActionButton color="red" icon="mdi-delete" @click="$emit('clear')">
        {{ $t('button.clear') }}
      </ActionButton>
    </v-col>
  </v-row>
</template>
<script>
import substringLength from '@/helpers/substringLength'
import {STATUS_ITEMS} from '@/helpers/variables/statusItems'

export default {
  name: 'CustomTableFilter',
  props: {
    params: {
      type: Object,
      default: () => {},
    },
    selectedFilters: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({}),
  methods: {
    substringLength,
    mapSelectItems(name) {
      switch (name) {
        case 'status':
          return STATUS_ITEMS
        default:
          return []
      }
    },
  },
}
</script>
