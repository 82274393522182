export const getItem = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key))
  } catch (error) {
    console.log('Ошибка при сохранении ключа в localStorage', error)
    return null
  }
}

export const setItem = (key, data) => {
  try {
    return localStorage.setItem(key, JSON.stringify(data))
  } catch (error) {
    console.log('Ошибка при получении ключа из localStorage', error)
    return null
  }
}

export const removeItem = (key) => {
  try {
    localStorage.removeItem(key)
  } catch (error) {
    console.log('Ошибка при удалении ключа из localStorage', error)
  }
}
