import axios from '@/plugins/axios'
import customErrors from '@/store/error'

const state = () => ({
  targetFiles: {},
  loading: false,
})

const getters = {}

const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_TARGET_FILES(state, {type, files}) {
    state.targetFiles[type] = files
  },
}

const actions = {
  async FILE_UPLOAD({commit, dispatch}, {type, formData}) {
    commit('SET_LOADING', true)
    try {
      const res = await axios.post('file/parse/text', formData)
      const {data, status} = res
      if (data && status === 200) {
        commit('SET_TARGET_FILES', {type, files: data})
      }
    } catch (err) {
      customErrors(err, dispatch)
    }
    commit('SET_LOADING', false)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
