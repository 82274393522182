var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',_vm._g(_vm._b({attrs:{"items":_vm.items,"outlined":_vm.outlined,"dense":_vm.dense,"clearable":_vm.clearable,"multiple":_vm.multiple,"disabled":_vm.disabled,"small-chips":_vm.smallChips,"deletable-chips":_vm.deletableChips,"append-icon":_vm.appendIcon,"item-text":"text","item-value":"value","required":"","no-data-text":_vm.$t('service.listIsEmpty')},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")])],1)]}}],null,true)},'v-list-item',attrs,false),on))]}},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
var attrs = ref.attrs;
var selected = ref.selected;
var select = ref.select;
return [(_vm.withOtherLine)?_c('div',{staticClass:"py-2"},[(index === 0)?_c('v-chip',[_vm._v(_vm._s(_vm.$t(item.text)))]):_vm._e(),(index === 1)?_c('span',{staticClass:"red--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.currentValue.length - 1)+" "+_vm._s(_vm.$t('label.others'))+") ")]):_vm._e()],1):_c('div',{staticClass:"pt-2 pb-1"},[_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.$emit('remove', item.value)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")])],1)]}},_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true),model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}},'v-autocomplete',_vm.$attrs,false),_vm.$listeners),[_vm._l((Object.keys(_vm.$slots)),function(slot){return _vm._t(slot,null,{"slot":slot})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }