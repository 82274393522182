<template>
  <div id="filepond">
    <file-pond
      ref="pond"
      name="file"
      :accepted-file-types="acceptedFileTypes"
      :label-idle="$t(label)"
      v-bind:allow-multiple="multiple"
      v-bind:files="myFiles"
      :server="server"
      :credits="null"
      :disabled="disabled"
      v-on:processfile="handleProcessFile"
      v-on:processfilerevert="handleProcessFileRevert"
    />
  </div>
</template>

<script>
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview)

import {mapActions, mapState} from 'vuex'

export default {
  name: 'FileUploader',
  components: {FilePond},
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'banner',
    },
    view: {
      type: String,
      default: '',
    },
    label: {
      type: [String, Object],
      default: '',
    },
    acceptedFileTypes: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    myFiles: [],
  }),
  computed: {
    ...mapState({
      token: (state) => state.auth.token,
      basicUrl: (state) => state.auth.basicUrl,
    }),
    server() {
      return {
        url: this.id
          ? this.basicUrl + `/file/creative/${this.type}/upload/${this.id}`
          : this.basicUrl + `/file/creative/${this.type}/upload`,
        process: {
          onerror: (err) => JSON.parse(err),
          headers: {
            Authorization: this.token,
          },
        },
        revert: null,
      }
    },
  },
  methods: {
    ...mapActions({
      showToastError: 'SHOW_TOAST_ERROR',
    }),
    handleProcessFile: function (error, file) {
      if (error?.body?.code) {
        this.showToastError(this.$i18n.t('toast.fileError'))
        return
      }
      this.$emit('uploaded', JSON.parse(file.serverId), this.view)
    },
    handleProcessFileRevert: function () {
      this.$emit('uploaded', {}, this.view)
    },
  },
}
</script>
<style lang="scss">
.filepond--wrapper {
  .filepond--root {
    margin-bottom: 0;
  }

  .filepond--panel-root {
    background-color: transparent;
  }

  .filepond--drop-label {
    background: var(--v-mainBg-base);
    border-radius: 4px;
    transition: 0.3s;
    border: 1px solid transparent;
  }
}

.theme--light .filepond--wrapper {
  .filepond--drop-label {
    border-color: rgba(0, 0, 0, 0.32);
    color: rgba(0, 0, 0, 0.6);
  }
}

.theme--dark .filepond--wrapper {
  .filepond--drop-label {
    border-color: rgba(255, 255, 255, 0.42);
    color: rgba(255, 255, 255, 0.7);
  }
}

#filepond,
#filepond label {
  cursor: pointer;
}

.file-error {
  .filepond--wrapper {
    .filepond--drop-label {
      border: 2px solid var(--v-error-base);
    }
  }
}
</style>
