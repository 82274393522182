import axios from '@/plugins/axios'
import i18n from '@/i18n/i18n'
import {getItem, setItem, removeItem} from '@/helpers/persistantStorage'
import {roles} from '@/helpers/variables/roles'
import {decrypt} from '@/helpers/cryptoStandards'
import customErrors from '@/store/error'

const state = () => ({
  userInfo: null,
  isTempPasswordExists: null,
  errorCount: 0,
  loading: false,
  permissions: null,
  token: null,
  basicUrl: process.env.VUE_APP_API_URL,
})

const getters = {
  isAdmin: (state) => state.userInfo?.roles[0] === roles[0].value,
  isManager: (state) => state.userInfo?.roles[0] === roles[1].value,
  isAdvertiser: (state) => state.userInfo?.roles[0] === roles[2].value,
  isAdvertiserPro: (state) => state.userInfo?.roles[0] === roles[3].value,
  isAdvertiserManager: (state) => state.userInfo?.roles[0] === roles[4].value,
  manager: (state) => {
    const {managerEmail = '-', managerFullName = '-', managerTgUsername = '-'} = state.userInfo
    return {
      email: managerEmail,
      fullName: managerFullName,
      tg: managerTgUsername,
    }
  },
}

const mutations = {
  SET_AUTH_INFO(state, data) {
    state.userInfo = data
  },
  SET_TEMP_PASSWORD(state, data) {
    state.isTempPasswordExists = data
  },
  SET_LOCAL_STORAGE(state, {roles, accessToken}) {
    setItem('nt_role', roles[0])
    setItem('nt_token', accessToken)
    state.token = accessToken
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_RESET_STATE(state) {
    state.userInfo = null
    state.loading = false
    state.isTempPasswordExists = false
  },
  SET_ERROR_COUNT(state, isReset = false) {
    state.errorCount = isReset ? 0 : state.errorCount + 1
  },
  SET_PERMISSIONS(state, payload) {
    state.permissions = decrypt(payload)
  },
  SET_TOKEN(state, token) {
    state.token = token
  },
}

const actions = {
  async LOGIN({commit, dispatch}, formData) {
    commit('SET_LOADING', true)
    try {
      const res = await axios.post('auth/login', formData)
      if (res.data && res.status === 200) {
        commit('SET_TEMP_PASSWORD', res.data.isTempPasswordExists)
        commit('SET_LOCAL_STORAGE', res.data)
        commit('SET_AUTH_INFO', res.data)
        commit('SET_PERMISSIONS', res.data.permissions)
        if (!res.data.isTempPasswordExists) {
          dispatch('SHOW_TOAST_SUCCESS', i18n.t('toast.login'), {root: true})
        }
      }
    } catch (err) {
      customErrors(err, dispatch)
      commit('SET_ERROR_COUNT')
    }
    commit('SET_LOADING', false)
  },
  async CHECK_USER({commit, dispatch}) {
    try {
      const res = await axios.get('auth/current-user')
      if (res.data && res.status === 200) {
        commit('SET_AUTH_INFO', res.data)
        commit('SET_PERMISSIONS', res.data.permissions)
        commit('SET_TOKEN', getItem('nt_token'))
      } else {
        dispatch('LOGOUT')
      }
      return res
    } catch (err) {
      dispatch('LOGOUT')
      customErrors(err, dispatch, 'SHOW_TOAST_ERROR', 'toast.authError')
      return err
    }
  },
  async UPDATE_TEMP_PASSWORD({commit, dispatch}, formData) {
    commit('SET_LOADING', true)
    try {
      const res = await axios.put('user/temp-password-agreement-currency', formData)
      if (res?.status === 200) {
        dispatch('SHOW_TOAST_SUCCESS', i18n.t('toast.login'), {root: true})
      }
      return res
    } catch (err) {
      customErrors(err, dispatch)
    }
    commit('SET_LOADING', false)
  },
  async LOGOUT({commit, dispatch}) {
    const token = getItem('nt_token')
    if (token) {
      try {
        removeItem('nt_role')
        removeItem('nt_token')
        commit('user/SET_BALANCE', null, {root: true})
        commit('user/SET_ITEMS', [], {root: true})
        dispatch('SHOW_TOAST_SUCCESS', i18n.t('toast.logout'), {root: true})
      } catch (err) {
        customErrors(err, dispatch)
      }
    }
    commit('SET_RESET_STATE')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
