import axios from '@/plugins/axios'
import customErrors from '@/store/error'
import i18n from '@/i18n/i18n'

const TYPE_LOADING = 'TARGET'
const TYPE_LOADING_ACTION = 'ACTION'

const state = () => ({
  items: [],
  item: null,
  loading: false,
  actionLoading: false,
  dataPagination: {},
})

const getters = {
  permission: (state, getters, rootState) => {
    return {
      read: rootState.auth?.permissions?.some((a) => a === 'TARGET_READ'),
      readAll: rootState.auth?.permissions?.some((a) => a === 'TARGET_READ_ALL'),
      new: rootState.auth.permissions?.some((a) => a === 'TARGET_CREATE'),
      newForClient: rootState.auth.permissions?.some((a) => a === 'TARGET_CREATE_FOR_CLIENT'),
      update: rootState.auth?.permissions?.some((a) => a === 'TARGET_UPDATE'),
      remove: rootState.auth?.permissions?.some((a) => a === 'TARGET_REMOVE'),
    }
  },
}

const mutations = {
  SET_ITEMS(state, items) {
    state.items = items
  },
  SET_ITEM_BY_ID(state, item) {
    state.item = item
  },
  SET_PAGINATION(state, data) {
    state.dataPagination = {
      pageNumber: data.number + 1,
      totalPages: data.totalPages,
    }
  },
  SET_LOADING(state, {type, loading}) {
    switch (type) {
      case TYPE_LOADING:
        state.loading = loading
        break
      case TYPE_LOADING_ACTION:
        state.actionLoading = loading
        break
    }
  },
}

const actions = {
  async GET_ITEMS({commit, dispatch}, params) {
    commit('SET_LOADING', {type: TYPE_LOADING, loading: true})
    commit('SET_ITEM_BY_ID', null)
    try {
      const res = await axios.get('target/all', {
        params: params,
      })
      if (res.status === 200) {
        commit('SET_ITEMS', res.data.content)
        commit('SET_PAGINATION', res.data)
      }
    } catch (err) {
      customErrors(err, dispatch)
      commit('SET_ITEMS', [])
    } finally {
      commit('SET_LOADING', {type: TYPE_LOADING, loading: false})
    }
  },
  async GET_ITEM_BY_ID({commit, dispatch}, id) {
    commit('SET_LOADING', {type: TYPE_LOADING, loading: true})
    try {
      const res = await axios.get(`target/view/${id}`)
      if (res.status === 200) {
        commit('SET_ITEM_BY_ID', res.data)
      }
    } catch (err) {
      customErrors(err, dispatch)
      commit('SET_ITEM_BY_ID', null)
    } finally {
      commit('SET_LOADING', {type: TYPE_LOADING, loading: false})
    }
  },
  async CREATE_TARGET({commit, dispatch}, formData) {
    commit('SET_LOADING', {type: TYPE_LOADING_ACTION, loading: true})
    try {
      const {userId, ...params} = formData
      const url = userId ? `target/create/${userId}` : 'target/create'
      const res = await axios.post(url, params)
      if (res.status === 200) {
        dispatch('SHOW_TOAST_SUCCESS', i18n.t('toast.successCreate'), {
          root: true,
        })
        return res
      }
    } catch (err) {
      customErrors(err, dispatch)
    } finally {
      commit('SET_LOADING', {type: TYPE_LOADING_ACTION, loading: false})
    }
  },
  async EDIT_TARGET({commit, dispatch}, {id, formData}) {
    commit('SET_LOADING', {type: TYPE_LOADING_ACTION, loading: true})
    try {
      const res = await axios.put(`target/update/${id}`, formData)
      if (res.status === 200) {
        dispatch('SHOW_TOAST_SUCCESS', i18n.t('toast.successEdit'), {
          root: true,
        })
        return res
      }
    } catch (err) {
      customErrors(err, dispatch)
    } finally {
      commit('SET_LOADING', {type: TYPE_LOADING_ACTION, loading: false})
    }
  },
  async DELETE_TARGET({commit, dispatch}, id) {
    commit('SET_LOADING', {type: TYPE_LOADING_ACTION, loading: true})
    try {
      const res = await axios.delete(`target/remove/${id}`)
      if (res.status === 200) {
        dispatch('SHOW_TOAST_SUCCESS', i18n.t('toast.successDeleted'), {
          root: true,
        })
      }
    } catch (err) {
      customErrors(err, dispatch)
    } finally {
      commit('SET_LOADING', {type: TYPE_LOADING_ACTION, loading: false})
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
