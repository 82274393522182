<template>
  <v-btn
    v-bind="$attrs"
    :color="color"
    :large="large"
    :small="small"
    :outlined="outlined"
    :height="height"
    :loading="loading"
    class="px-15"
    :width="width"
    v-on="$listeners"
  >
    <v-icon v-if="icon !== ''" :class="outlined ? '' : titleColor" left>
      {{ icon }}
    </v-icon>
    <span :class="outlined ? '' : titleColor"><slot /></span>
  </v-btn>
</template>

<script>
export default {
  name: 'ActionButton',
  inheritAttrs: false,
  props: {
    color: {
      type: String,
      default: 'activeLink',
    },
    titleColor: {
      type: String,
      default: 'white--text',
    },
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: 'auto',
    },
    height: {
      type: [Number, String],
      default: 40,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
