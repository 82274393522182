<template>
  <div>
    <LeftDrawer />
    <AppBar />
    <v-main>
      <v-container fluid class="px-8 px-sm-10 pt-15 pb-20">
        <v-slide-y-transition mode="out-in">
          <router-view />
        </v-slide-y-transition>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import LeftDrawer from '../components/navigations/LeftDrawer'
import AppBar from '../components/navigations/AppBar'

export default {
  main: 'MainLayout',
  components: {LeftDrawer, AppBar},
  data: () => ({}),
  computed: {},
}
</script>
