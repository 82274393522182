<template>
  <v-tooltip max-width="800" v-bind="$attrs" :color="color" :open-delay="openDelay" :nudge-right="nudgeRight">
    <template #activator="{on, value}">
      <slot name="activator" :on="on" :value="value" />
    </template>
    <span class="text-lowercase px-1">{{ $t(message) }}</span>
  </v-tooltip>
</template>
<script>
export default {
  name: 'TooltipEffect',
  inheritAttrs: false,
  props: {
    message: {
      type: [String, Number],
      default: '',
    },
    color: {
      type: String,
      default: 'activeLink',
    },
    openDelay: {
      type: [String, Number],
      default: 100,
    },
    nudgeRight: {
      type: [String, Number],
      default: 0,
    },
  },
}
</script>
