import i18n from '@/i18n/i18n'

const customErrors = (err, dispatch, view = 'SHOW_TOAST_ERROR', message) => {
  const {response: {data = {}} = {}} = err
  if (data?.code === 'BAD_CREDENTIALS') {
    dispatch(view, i18n.t('toast.authCredentials'), {root: true})
  } else if (data?.code === 'EMAIL_IN_USE') {
    dispatch(view, i18n.t('toast.emailUse'), {root: true})
  } else if (data?.code === 'PHONE_IN_USE') {
    dispatch(view, i18n.t('toast.phoneUse'), {root: true})
  } else if (data?.code === 'CONTRACT_NUMBER_IN_USE') {
    dispatch(view, i18n.t('toast.contractNumberUse'), {root: true})
  } else if (data?.code === 'CREATE_TARGET_ERROR') {
    dispatch(view, i18n.t('toast.createTargetError'), {root: true})
  } else {
    dispatch(view, i18n.t(message) || i18n.t('toast.error') || data.message, {root: true})
  }
}

export default customErrors
