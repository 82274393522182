const roles = ['ROLE_ADVERTISER', 'ROLE_ADVERTISER_PRO', 'ROLE_ADVERTISER_MANAGER']

export default [
  {
    path: '/advertiser/statistics',
    name: 'AdvertiserStatistics',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "advertiser-statistics" */ '@/views/statistics/Statistics'),
  },
  {
    path: '/advertiser/campaigns',
    name: 'AdvertiserCampaigns',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "advertiser-campaigns" */ '@/views/campaigns/Campaigns'),
  },
  {
    path: '/advertiser/creatives',
    name: 'AdvertiserCreatives',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "advertiser-creatives" */ '@/views/creatives/Creatives'),
  },
  {
    path: '/advertiser/creative/detail/:id',
    name: 'AdvertiserCreativeDetail',
    props: true,
    meta: {layout: 'main', auth: true, role: roles},
    component: () =>
      import(/* webpackChunkName: "advertiser-creative-detail" */ '@/views/creatives/CreativeDetail'),
  },
  {
    path: '/advertiser/creative/new',
    name: 'AdvertiserNewCreative',
    meta: {layout: 'main', auth: true, role: roles},
    component: () =>
      import(/* webpackChunkName: "advertiser-creative-new" */ '@/views/creatives/CreativeDetail'),
  },
  {
    path: '/advertiser/targets',
    name: 'AdvertiserTargets',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "advertiser-targets" */ '@/views/targets/Targets'),
  },
  {
    path: '/advertiser/targets/detail/:id',
    name: 'AdvertiserTargetDetail',
    props: true,
    meta: {layout: 'main', auth: true, role: roles},
    component: () =>
      import(/* webpackChunkName: "advertiser-target-detail" */ '@/views/targets/TargetDetail'),
  },
  {
    path: '/advertiser/targets/new',
    name: 'AdvertiserNewTarget',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "advertiser-target-new" */ '@/views/targets/TargetDetail'),
  },
  {
    path: '/advertiser/rules',
    name: 'AdvertiserRules',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "advertiser-rules" */ '@/views/rules/Rules'),
  },
  {
    path: '/advertiser/segments',
    name: 'AdvertiserSegments',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "advertiser-segments" */ '@/views/segments/Segments'),
  },
  {
    path: '/advertiser/doc',
    name: 'AdvertiserDoc',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "advertiser-doc" */ '@/views/doc/Doc'),
  },
]
