const managerRoutes = {
  clients: {
    order: 1,
    route: {name: 'ManagerClients'},
  },
  dashboard: {
    order: 2,
    route: {name: 'ManagerDashboard'},
  },
  statistics: {
    order: 3,
    route: {name: 'ManagerStatistics'},
  },
  campaigns: {
    order: 4,
    route: {name: 'ManagerCampaigns'},
  },
  creatives: {
    order: 5,
    route: {name: 'ManagerCreatives'},
  },
  targets: {
    order: 6,
    route: {name: 'ManagerTargets'},
  },
  segments: {
    order: 7,
    route: {name: 'ManagerSegments'},
  },
  rules: {
    order: 8,
    route: {name: 'ManagerRules'},
  },
  doc: {
    order: 9,
    route: {name: 'ManagerDoc'},
  },
}

export default managerRoutes
