<template>
  <v-card :elevation="elevation">
    <v-data-table
      :headers="headers"
      :items="items"
      :loader-height="2"
      :mobile-breakpoint="0"
      :header-props="{sortIcon: null}"
      :disable-sort="disableSort"
      :custom-sort="() => items"
      :show-select="showSelect"
      checkbox-color="blue"
      hide-default-footer
      disable-pagination
      class="custom-table rounded-10"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-for="th in headers" #[`header.${th.value}`]="{}">
        <div :key="th.value" class="custom-table__header">
          <span class="table-title">{{ th.text }}</span>
          <v-btn
            v-if="th.isFilter && isPermission"
            icon
            small
            class="custom-table__filter ml-2"
            :color="checkActivated(selectedFilters, th.value) ? 'blue' : 'gray'"
            @click.stop="$emit('filterAction', th)"
          >
            <v-icon size="16"> mdi-filter-outline </v-icon>
          </v-btn>
        </div>
      </template>

      <v-progress-linear slot="progress" color="red" height="2" indeterminate />

      <template #no-data>
        <div class="d-flex justify-center py-8">
          <span class="font-18 font-weight-regular">{{ $t('table.noData') }}</span>
        </div>
      </template>

      <template #loading>
        <div class="d-flex justify-center py-8">
          <span class="font-18 font-weight-regular">{{ $t('table.loading') }}</span>
        </div>
      </template>

      <template #no-results>
        <div class="d-flex justify-center py-8">
          <span class="font-18 font-weight-regular">{{ $t('table.noResults') }}</span>
        </div>
      </template>

      <slot v-for="slot in Object.keys($slots)" :slot="slot" :name="slot" />

      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: 'CustomTable',
  inheritAttrs: false,
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    selectedFilters: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => {},
    },
    disableSort: {
      type: Boolean,
      default: false,
    },
    sortNameParam: {
      type: String,
      default: 'id',
    },
    sortSetParam: {
      type: String,
      default: 'desc',
    },
    elevation: {
      type: [Number, String],
      default: 1,
    },
    isPermission: {
      type: Boolean,
      default: false,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    checkActivated(filters, value) {
      return filters.some((a) => a.name === value)
    },
  },
  watch: {
    options: {
      deep: true,
      handler(sortOptions) {
        if (sortOptions.sortBy.length) {
          this.$emit('changeSortParams', {
            sortNameParam: sortOptions.sortBy[0],
            sortSetParam: sortOptions.sortDesc[0] ? 'desc' : 'asc',
          })
        } else {
          this.$emit('changeSortParams', {
            sortNameParam: undefined,
            sortSetParam: undefined,
          })
        }
      },
    },
  },
}
</script>
<style lang="scss">
.custom-table {
  &.v-data-table {
    .v-data-table__wrapper {
      table {
        thead {
          tr {
            th.active.desc .table-title {
              color: var(--v-red-base) !important;
            }

            th.active.asc .table-title {
              color: var(--v-green-base) !important;
            }
          }
        }
      }
    }
  }

  &__header {
    position: relative;
  }

  &__filter {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
