<template>
  <v-app>
    <component :is="layout">
      <router-view />
    </component>
    <CustomToaster :value="toaster" :message="message" :color="color" />
  </v-app>
</template>

<script>
import MainLayout from './layouts/MainLayout'
import EmptyLayout from './layouts/EmptyLayout'

import {mapState} from 'vuex'

export default {
  name: 'App',
  components: {MainLayout, EmptyLayout},
  computed: {
    ...mapState({
      toaster: (state) => state.toaster,
      message: (state) => state.message,
      color: (state) => state.color,
    }),
    layout() {
      return (this.$route.meta.layout || 'empty') + '-layout'
    },
  },
}
</script>
