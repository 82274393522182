<template>
  <v-sheet :width="width">
    <v-select
      v-model="currentLanguage"
      :items="items"
      dense
      solo
      flat
      required
      append-icon=""
      class="custom-language"
      hide-details
      :menu-props="{bottom: true, offsetY: true}"
      @change="changeLocale"
    >
      <template #selection="{item}">
        <!--        <v-img alt="country" :src="require(`@/assets/images/flags/${item}.png`)" max-width="30" />-->
        {{ item.text }}
      </template>
      <template #item="{item}">
        <!--        <v-img alt="country" :src="require(`@/assets/images/flags/${item}.png`)" max-width="30" />-->
        {{ item.text }}
      </template>
    </v-select>
  </v-sheet>
</template>

<script>
import {getItem, setItem} from '@/helpers/persistantStorage'
import {mapMutations, mapState} from 'vuex'

export default {
  name: 'LanguageSelect',
  inheritAttrs: false,
  data: () => ({
    items: [
      {text: 'En', value: 'en'},
      {text: 'Ru', value: 'ru'},
    ],
  }),
  props: {
    width: {
      type: [String, Number],
      default: 55,
    },
  },
  computed: {
    ...mapState({
      isCheckLocale: (state) => state.isCheckLocale,
    }),
    currentLanguage: {
      get() {
        return getItem('nt_system_language') || 'en'
      },
      set() {
        const locale = this.$i18n.locale
        switch (locale) {
          case 'ru':
            return 'ru'
          case 'en':
            return 'en'
          default:
            return 'ru'
        }
      },
    },
  },
  methods: {
    ...mapMutations({
      changeCheckLocale: 'CHANGE_LOCALE',
    }),
    changeLocale(locale) {
      this.$i18n.locale = locale
      setItem('nt_system_language', locale)
      this.changeCheckLocale({check: false, locale})
      this.$nextTick(() => {
        this.changeCheckLocale({check: true, locale})
      })
    },
  },
}
</script>
