<template>
  <v-select
    v-model="select"
    v-bind="$attrs"
    :items="items"
    :outlined="outlined"
    :dense="dense"
    :solo="solo"
    :flat="flat"
    :rounded="rounded"
    :disabled="disabled"
    :readonly="readonly"
    required
    :append-icon="appendIcon"
    item-text="text"
    item-value="value"
    :no-data-text="$t('service.listIsEmpty')"
    @change="change"
  >
    <template #selection="{item}">{{ $t(item.text) }}</template>
    <template #item="{item}">{{ $t(item.text) }}</template>

    <slot v-for="slot in Object.keys($slots)" :slot="slot" :name="slot" />
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'CustomSelect',
  inheritAttrs: true,
  props: {
    value: {
      type: [String, Number, Object, Array, Boolean],
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    appendIcon: {
      type: String,
      default: 'mdi-chevron-down',
    },
  },
  computed: {
    select: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    change(value) {
      this.$emit('change', value)
    },
  },
}
</script>
