import Vue from 'vue'
import Vuex from 'vuex'
import {getItem} from '@/helpers/persistantStorage'

Vue.use(Vuex)

import auth from '@/store/modules/auth'
import user from '@/store/modules/user'
import dictionary from '@/store/modules/dictionary'
import target from '@/store/modules/target'
import file from '@/store/modules/file'
import creative from '@/store/modules/creative'
import filter from '@/store/modules/filter'

export default new Vuex.Store({
  state: {
    message: '',
    color: 'green',
    toaster: false,
    isCheckLocale: true,
    locale: getItem('nt_system_language') || 'en',
  },
  mutations: {
    SHOW_TOAST(state, payload) {
      state.toaster = payload
    },
    SHOW_TOAST_MESSAGE(state, message) {
      state.message = message
    },
    CHANGE_COLOR(state, color) {
      state.color = color
    },
    CHANGE_LOCALE(state, {check, locale}) {
      state.isCheckLocale = check
      state.locale = locale
    },
  },
  actions: {
    SHOW_TOAST_SUCCESS({commit}, message) {
      commit('CHANGE_COLOR', 'green')
      commit('SHOW_TOAST_MESSAGE', message)
      commit('SHOW_TOAST', true)
    },
    SHOW_TOAST_INFO({commit}, message) {
      commit('CHANGE_COLOR', 'orange darken-1')
      commit('SHOW_TOAST_MESSAGE', message)
      commit('SHOW_TOAST', true)
    },
    SHOW_TOAST_ERROR({commit}, message) {
      commit('CHANGE_COLOR', 'red darken-1')
      commit('SHOW_TOAST_MESSAGE', message)
      commit('SHOW_TOAST', true)
    },
    CLOSE_TOAST({commit}) {
      commit('SHOW_TOAST', false)
    },
  },
  modules: {
    auth,
    user,
    dictionary,
    target,
    file,
    creative,
    filter,
  },
})
