<template>
  <v-app-bar app color="white" :height="checkXsOnly ? 150 : 75" class="app-bar elevation-1">
    <div class="d-flex flex-grow-1 align-center justify-space-between flex-column flex-sm-row">
      <v-img alt="logo" :src="logoSrc" max-width="140" contain />
      <div class="d-flex align-center justify-center flex-wrap mt-7 mt-sm-0">
        <div v-if="balance" class="mt-3 mr-3">{{ balance.amount }} {{ balance.currency }}</div>
        <LanguageSelect class="mt-3 mr-4" />
        <CustomSwitch
          v-model="currentThemes"
          :inset="false"
          class="mt-3 mr-7 mr-md-9 theme-switch"
          @change="switchThemes(currentThemes)"
        />
        <v-menu
          :close-on-content-click="isClose"
          allow-overflow
          :min-width="checkXsOnly ? 310 : 360"
          offset-y
          nudge-bottom="20"
          nudge-left="250"
        >
          <template #activator="{on}">
            <IconButton class="mt-3" v-on="on" @click="openMenu">
              <v-icon>mdi-account</v-icon>
            </IconButton>
          </template>
          <v-list class="app-bar__profile-list">
            <v-list-item to="/profile" class="my-2" @click="closeMenu">
              <v-icon>mdi-account-box</v-icon>
              <span class="pl-10 font-weight-bold">{{ $t('menu.profile') }}</span>
            </v-list-item>
            <v-list-item v-if="isShowManager" class="my-2">
              <div>
                <div>
                  {{ $t('title.manager') }}: <span class="pl-2">{{ manager.fullName }}</span>
                </div>
                <div class="d-flex flex-wrap">
                  <a :href="`mailto:${manager.email}`" title="email" target="_blank" class="pr-3">
                    <v-img :src="require(`@/assets/images/icon/email.svg`)" max-width="30" />
                  </a>
                  <a :href="`https://t.me/${manager.tg}`" target="_blank" title="telegram">
                    <v-img :src="require(`@/assets/images/icon/telegram.svg`)" max-width="30" />
                  </a>
                </div>
              </div>
            </v-list-item>
            <v-list-item class="my-2" @click="logoutHandler">
              <span>{{ $t('button.logout') }}</span>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {setItem} from '@/helpers/persistantStorage'

export default {
  name: 'AppBar',
  inheritAttrs: false,
  data: () => ({
    theme: 'dark',
    currentThemes: false,
    isClose: false,
  }),
  mounted() {
    this.loadBalance()
    this.currentThemes = this.$vuetify.theme.isDark
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.auth.userInfo,
      balance: (state) => state.user.balance,
    }),
    ...mapGetters({
      manager: 'auth/manager',
      isAdvertiser: 'auth/isAdvertiser',
      isAdvertiserPro: 'auth/isAdvertiserPro',
      isAdvertiserManager: 'auth/isAdvertiserManager',
    }),
    isShowManager() {
      return this.isAdvertiser || this.isAdvertiserPro || this.isAdvertiserManager
    },
    logoSrc() {
      return this.$vuetify.theme.dark
        ? require('@/assets/images/nt_logo_white.png')
        : require('@/assets/images/nt_logo_blue.png')
    },
    checkXsOnly() {
      return this.$vuetify.breakpoint.xsOnly
    },
  },
  methods: {
    ...mapActions({
      logout: 'auth/LOGOUT',
      getBalance: 'user/GET_BALANCE',
    }),
    async loadBalance() {
      if (this.isShowManager) await this.getBalance()
    },
    switchThemes(switches) {
      this.$vuetify.theme.dark = switches
      setItem('nt_theme', switches ? 'dark' : 'light')
    },
    logoutHandler() {
      this.logout()
      this.$router.push({name: 'Login'})
    },
    openMenu() {
      if (this.isClose) this.isClose = false
    },
    closeMenu() {
      this.isClose = true
    },
  },
}
</script>
<style lang="scss">
.app-bar {
  &__profile-list {
    .v-list-item--active {
      color: var(--v-blue-base);

      &:before {
        opacity: 0;
      }
    }
  }

  .theme-switch {
    &.v-input--switch {
      .v-input--switch__thumb {
        &:before {
          content: '';
          width: 85%;
          height: 85%;
        }

        &.theme--light {
          &:before {
            background: url(~@/assets/images/icon/white-balance-sunny.svg) no-repeat;
          }
        }

        &.theme--dark {
          &:before {
            background: url(~@/assets/images/icon/moon-waning-crescent.svg) no-repeat;
            transform: rotate(-30deg);
          }
        }
      }
    }
  }
}
</style>
