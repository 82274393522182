const advertiserRoutes = {
  clients: {
    order: 1,
    route: {name: 'AdvertiserClients'},
  },
  statistics: {
    order: 2,
    route: {name: 'AdvertiserStatistics'},
  },
  campaigns: {
    order: 3,
    route: {name: 'AdvertiserCampaigns'},
  },
  creatives: {
    order: 4,
    route: {name: 'AdvertiserCreatives'},
  },
  targets: {
    order: 5,
    route: {name: 'AdvertiserTargets'},
  },
  segments: {
    order: 6,
    route: {name: 'AdvertiserSegments'},
  },
  rules: {
    order: 7,
    route: {name: 'AdvertiserRules'},
  },
  doc: {
    order: 8,
    route: {name: 'AdvertiserDoc'},
  },
}

export default advertiserRoutes
