<template>
  <v-text-field
    v-model="inputValue"
    v-bind="$attrs"
    :outlined="outlined"
    :solo="solo"
    :dense="dense"
    :readonly="readonly"
    required
    @keydown="keydown"
    @keypress="keypress"
    @change="change"
    @click:append="append"
  >
    <slot v-for="slot in Object.keys($slots)" :slot="slot" :name="slot" />

    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: 'CustomInput',
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    keydown(value) {
      this.$emit('keydown', value)
    },
    keypress(value) {
      this.$emit('keypress', value)
    },
    change(value) {
      this.$emit('change', value)
    },
    append(value) {
      this.$emit('click:append', value)
    },
  },
}
</script>
