<template>
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant="miniDrawer"
    mobile-breakpoint="0"
    mini-variant-width="76"
    width="285"
    class="drawer-wrapper elevation-1"
    app
    floating
  >
    <div class="d-flex align-center my-10">
      <v-icon
        :class="miniDrawer ? 'mx-13' : 'mx-12'"
        :color="miniDrawer ? 'activeLink' : ''"
        @click="openDrawer"
      >
        mdi-menu
      </v-icon>
    </div>
    <v-list class="drawer-wrapper__menu pa-0">
      <template v-for="item in items">
        <v-list-item
          :key="item.page"
          class="nav-item"
          :to="item.route"
          :class="{'active-route': activeRoute(item)}"
          :ripple="false"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="navItem">
              {{ $t(item.title) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {mapGetters} from 'vuex'

import adminRoutes from '@/helpers/routes/admin'
import advertiserRoutes from '@/helpers/routes/advertiser'
import managerRoutes from '@/helpers/routes/manager'
import {setItem, getItem} from '@/helpers/persistantStorage'

export default {
  name: 'LeftDrawer',
  inheritAttrs: false,
  props: {},
  data: () => ({
    items: [
      {
        page: 'clients',
        title: 'menu.clients',
        icon: 'mdi-account-supervisor',
        activeRoute: 'clients',
      },
      {
        page: 'dashboard',
        title: 'menu.dashboard',
        icon: 'mdi-view-dashboard',
        activeRoute: 'dashboard',
      },
      {
        page: 'statistics',
        title: 'menu.statistics',
        icon: 'mdi-chart-line',
        activeRoute: 'statistics',
      },
      {
        page: 'campaigns',
        title: 'menu.campaigns',
        icon: 'mdi-store-outline',
        activeRoute: 'campaigns',
      },
      {
        page: 'creatives',
        title: 'menu.creatives',
        icon: 'mdi-monitor',
        activeRoute: 'creative',
      },
      {
        page: 'targets',
        title: 'menu.targets',
        icon: 'mdi-target',
        activeRoute: 'targets',
      },
      {
        page: 'segments',
        title: 'menu.segments',
        icon: 'mdi-segment',
        activeRoute: 'segments',
      },
      {
        page: 'rules',
        title: 'menu.rules',
        icon: 'mdi-book-open',
        activeRoute: 'rules',
      },
      {
        page: 'integrations',
        title: 'menu.integrations',
        icon: 'mdi-cog-transfer',
        activeRoute: 'integrations',
      },
      {
        page: 'doc',
        title: 'menu.docs',
        icon: 'mdi-file-document-outline',
        activeRoute: 'doc',
      },
      {
        page: 'tags',
        title: 'menu.tags',
        icon: 'mdi-tag-outline',
        activeRoute: 'tags',
      },
    ],
    drawer: true,
    miniDrawer: false,
  }),
  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      isManager: 'auth/isManager',
      isAdvertiser: 'auth/isAdvertiser',
      isAdvertiserPro: 'auth/isAdvertiserPro',
      isAdvertiserManager: 'auth/isAdvertiserManager',
    }),
  },
  mounted() {
    this.loadMenu()
    this.miniDrawer = !!getItem('nt_menu_action')
  },
  methods: {
    loadMenu() {
      let deactivatePages = []
      switch (true) {
        case this.isAdmin:
          this.items = this.parseMenu(deactivatePages, this.items, adminRoutes)
          this.items = this.items.map((a) => {
            if (a.page === 'clients') a.title = 'menu.users'
            return a
          })
          break
        case this.isManager:
          deactivatePages = ['integrations', 'tags']
          this.items = this.parseMenu(deactivatePages, this.items, managerRoutes)
          break
        case this.isAdvertiserManager:
          deactivatePages = ['dashboard', 'integrations', 'tags']
          this.items = this.parseMenu(deactivatePages, this.items, advertiserRoutes)
          break
        case this.isAdvertiser || this.isAdvertiserPro:
          deactivatePages = ['clients', 'dashboard', 'integrations', 'tags']
          this.items = this.parseMenu(deactivatePages, this.items, advertiserRoutes)
          break
        default:
          return this.items
      }
    },
    parseMenu(data, items, routes) {
      if (data.length) {
        data.forEach(
          (a) =>
            (items = items.filter((b) => {
              if (a !== b.page) return b
            }))
        )
      }
      items = items
        .map((a) => ({
          ...a,
          route: routes[a.page]?.route,
          order: routes[a.page]?.order,
        }))
        .sort((b, c) => b.order - c.order)
      return items
    },
    activeRoute(item) {
      return this.$route.fullPath.indexOf(item.activeRoute.toLowerCase()) !== -1
    },
    openDrawer() {
      this.miniDrawer = !this.miniDrawer
      setItem('nt_menu_action', this.miniDrawer)
    },
  },
}
</script>
