import Vue from 'vue'
import VueRouter from 'vue-router'
import {getItem} from '@/helpers/persistantStorage'
import store from '@/store'
import {roles} from '@/helpers/variables/roles'

const adminRole = roles[0].value
const managerRole = roles[1].value
const advertiserRole = roles[2].value
const advertiserProRole = roles[3].value
const advertiserManagerRole = roles[4].value

import admin from './modules/admin'
import manager from './modules/manager'
import advertiser from './modules/advertiser'
import advertiserManager from './modules/advertiserManager'

Vue.use(VueRouter)

const baseRoutes = [
  {
    path: '/login',
    name: 'Login',
    meta: {layout: 'empty'},
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/Login'),
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: {layout: 'main', auth: true},
    component: () => import(/* webpackChunkName: "profile" */ '@/views/profile/Profile'),
  },
  {
    path: '*',
    name: 'Error',
    redirect: () => {
      const token = getItem('nt_token')
      const role = getItem('nt_role')
      if (token) {
        if (role === adminRole) return {name: 'AdminDashboard'}
        if (role === managerRole) return {name: 'ManagerClients'}
        if (role === advertiserManagerRole) return {name: 'AdvertiserClients'}
        if (role === advertiserRole || role === advertiserProRole) return {name: 'AdvertiserStatistics'}
      } else {
        return {name: 'Login'}
      }
    },
  },
]

const routes = baseRoutes.concat(admin, manager, advertiser, advertiserManager)

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((m) => m.meta.auth) && !store.state.auth?.userInfo?.email) {
    await store.dispatch('auth/CHECK_USER')
    const role = store.state.auth?.userInfo?.roles[0]

    if (to.name === 'Profile') {
      return next()
    }

    if (role) {
      if (role === adminRole) {
        if (to.meta.role.includes(role)) {
          next()
        } else {
          next({name: 'AdminDashboard'})
        }
      }
      if (role === managerRole) {
        if (to.meta.role.includes(role)) {
          next()
        } else {
          next({name: 'ManagerClients'})
        }
      }
      if (role === advertiserManagerRole) {
        if (to.meta.role.includes(role)) {
          next()
        } else {
          next({name: 'AdvertiserClients'})
        }
      }
      if (role === advertiserRole || role === advertiserProRole || role === advertiserManagerRole) {
        if (to.meta.role.includes(role)) {
          next()
        } else {
          next({name: 'AdvertiserStatistics'})
        }
      }
    } else {
      next({name: 'Login'})
    }
  }

  if (to.matched.some((m) => !!m.meta.role)) {
    if (to.matched.some((m) => !!m.meta.auth)) {
      next()
    } else {
      next({name: 'Login'})
    }
  } else {
    next()
  }
})

export default router
