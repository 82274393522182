const roles = ['ROLE_ADVERTISER_MANAGER']

export default [
  {
    path: '/advertiser/clients',
    name: 'AdvertiserClients',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "advertiser-clients" */ '@/views/clients/Clients'),
  },
  {
    path: '/advertiser/clients/detail/:id',
    name: 'AdvertiserClientDetail',
    props: true,
    meta: {layout: 'main', auth: true, role: roles},
    component: () =>
      import(/* webpackChunkName: "advertiser-client-detail" */ '@/views/clients/ClientDetail'),
  },
  {
    path: '/advertiser/clients/new',
    name: 'AdvertiserNewClient',
    meta: {layout: 'main', auth: true, role: roles},
    component: () => import(/* webpackChunkName: "advertiser-client-new" */ '@/views/clients/ClientDetail'),
  },
]
