export const roles = [
  {
    text: 'ADMIN',
    value: 'ROLE_ADMIN',
  },
  {
    text: 'MANAGER',
    value: 'ROLE_MANAGER',
  },
  {
    text: 'ADVERTISER',
    value: 'ROLE_ADVERTISER',
  },
  {
    text: 'ADVERTISER_PRO',
    value: 'ROLE_ADVERTISER_PRO',
  },
  {
    text: 'ADVERTISER_MANAGER',
    value: 'ROLE_ADVERTISER_MANAGER',
  },
]

export const getRoles = (array) => {
  return roles.filter((item) => {
    for (let key in array) {
      if (item.value === undefined || item.value === array[key]) return false
    }
    return true
  })
}
